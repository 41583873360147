<template>
  <div id="base">
    <LoadingScreen :loading="loading_screen"/>
    <div class="container is-fullhd" v-if="!loading_screen">
      <div class="columns">
        <div class="column pr-0 is-2 is-hidden-touch">
          <SideBar :userData="userData" :menus="menus"></SideBar>
        </div>
        <div class="column pl-0">
          <NavBar :userData="userData" @openDropdown="open_menu=true" @logout="logout"></NavBar>
          <modal-menu :show="open_menu" :userData="userData" :menus="menus" @close="open_menu=false" @logout="logout"></modal-menu>

          <div class="mainBase">
            <router-view class="p-4"
            :tokenData="tokenData"
            :userData="userData"
            :loaded="loaded"
            :branchs="branchs"
            :nodes="nodes"
            :devices="devices"
            :device_log="DeviceLogSort"
            :client="client"
            :connected="connected"
            @get_branch="get_branch"
            @get_node="get_node"
            @get_device="get_device"
            @get_device_log="get_device_log"
            @connectMQTT="connectMQTT"
            @subscribeMQTT="subscribeMQTT"/>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen'
import SideBar from '@/components/SideBar'
import NavBar from '@/components/NavBar'
import ModalMenu from '@/components/Modal/Menu'

var mqtt = require('mqtt');

// const TOPIC = "tnj-electronics/localhost/";
const TOPIC = "tnj-electronics/powermeter999/";
const HOST = "ws://broker.hivemq.com:8000/mqtt";

export default {
  name: 'MemberBase',
  components:{ LoadingScreen, SideBar, NavBar, ModalMenu },
  data(){
    return {
      tokenData: {},
      userData: {},
      loaded: {},
      branchs: [],
      nodes: [],
      devices: [],
      device_log: [],
      menus:[
        {
          title: "มอนิเตอร์",
          name: "MemberMornitor",
          icon: "fas fa-desktop",
          href: "/member/mornitor",
          bg: "is-warning",
        },
        {
          title: "ข้อมูลผู้ใช้",
          name: "MemberProfile",
          icon: "fas fa-user",
          href: "/member/profile",
          bg: "is-info",
        },
        {
          title: "รายงาน",
          name: "MemberReport",
          icon: "fas fa-file-alt",
          href: "/member/report",
          bg: "is-info",
        },
      ],
      open_menu: false,
      client: null,
      connected: false,
      subscribed: [],
    }
  },
  created(){
    // setTimeout(()=> this.loading_screen = false, 2000);
    this.tokenData = this.LocalStorage("_TNJ_PowerMeter_tokenData_");
  },
  mounted(){
    this.get_userData();
  },
  methods:{
    async get_userData(){
      var url = this.api+'user/get_userData/'+this.secret;
      var data = new FormData();
      data.set("email", this.tokenData.email);
      data.set("token", this.tokenData.token);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          this.userData = response.data.data;
          if(this.isAdmin(this.userData)) this.redirect("/admin");
        }else{
          this.redirect("/");
        }
      })
      .catch((error)=>{
        console.error(error);
        this.$swal("","","warning");
      })
      .then(()=>{
        this.check_login(this.userData);
      })
    },
    async get_branch(){
      var url = this.api+'user/get_branch/'+this.secret;
      var data = new FormData();
      data.set("u_id", this.userData.id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          this.branchs = response.data.data;
        }
      })
      .catch((error)=>{
        console.error(error);
        this.$swal("","get_branch()","warning");
      })
      .then(()=>{
        this.loaded.get_branch = true;
      })
    },
    async get_node(b_id){
      var url = this.api+'user/get_node/'+this.secret;
      var data = new FormData();
      data.set("b_id", b_id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          if(!this.nodes.length){
            this.nodes = response.data.data;
          }else{
             response.data.data.forEach((e) => {
               this.nodes.push(e);
            });
          }
        }
      })
      .catch((error)=>{
        console.error(error);
        this.$swal("","get_node()","warning");
      })
      .then(()=>{
        if(!this.loaded.get_node){
          this.loaded.get_node = [];
        }
        this.loaded.get_node.push(b_id);
      })
    },
    async get_device(n_id){
      var url = this.api+'user/get_device/'+this.secret;
      var data = new FormData();
      data.set("n_id", n_id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          if(!this.devices.length){
            this.devices = response.data.data;
          }else{
             response.data.data.forEach((e) => {
               this.devices.push(e);
            });
          }
        }
      })
      .catch((error)=>{
        console.error(error);
        this.$swal("","get_device()","warning");
      })
      .then(()=>{
        if(!this.loaded.get_device){
          this.loaded.get_device = [];
        }
        this.loaded.get_device.push(n_id);
      })
    },
    async get_device_log(d_id){
      var url = this.api+'user/get_device_log/'+this.secret;
      var data = new FormData();
      data.set("d_id", d_id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          if(!this.device_log.length){
            this.device_log = response.data.data;
          }else{
             response.data.data.forEach((e) => {
               this.device_log.push(e);
            });
          }
        }
      })
      .catch((error)=>{
        console.error(error);
        this.$swal("","get_device_log()","warning");
      })
      .then(()=>{
        if(!this.loaded.get_device_log){
          this.loaded.get_device_log = [];
        }
        this.loaded.get_device_log.push(d_id);
      })
    },
    currentPage(menu){
      return this.currentRouteName==menu.name;
    },
    connectMQTT(mac){
      let self = this;

      this.client = mqtt.connect(HOST);
      this.client.on('connect', function(){
        self.connected = true;
        console.log('Connected: %csuccessfully','background: green; color: white');

        self.subscribeMQTT(mac);
      });
      this.client.on('error', function (error) {
        self.connected = false;
        console.error(error);
      });
      this.client.on('message', function (topic, message) {
        // console.log(topic,message.toString());
        self.validate_value(topic,message.toString());
      });
    },
    subscribeMQTT(mac){
      if(this.client){
        const INDEX = this.subscribed.map((x) => x).indexOf(mac);
        if(INDEX!==-1){
          console.log("already subscribe",mac);
        }else{
          this.subscribed.push(mac);
          this.client.subscribe(TOPIC+mac);
          console.log(TOPIC+mac);
        }
      }
    },
    validate_value(topic,message){
      const MAC = topic.split('/').reverse()[0];
      const INDEX = this.nodes.map((n) => n.mac).indexOf(MAC);
      if(INDEX==-1){
        console.error("Unknow topic: ",MAC);
        return false;
      }

      try {
        let json = JSON.parse(message);
        // console.log( json );

        switch (true) {
          case (!json.Key):
            console.error("Not Found Key!!!");
            break;
          case (json.Key!=="6c6756c82eca0bdd4c1340fa24d4f6081e906aeb"):
            console.error("Invalid Key",json.Key);
            break;
          case (typeof json.ID=="undefined"):
            console.error("Invalid Value: ID",json);
            break;
          case (typeof json.Current_a=="undefined"):
            console.error("Invalid Value: Current_a",json);
            break;
          case (typeof json.Current_b=="undefined"):
            console.error("Invalid Value: Current_b",json);
            break;
          case (typeof json.Current_c=="undefined"):
            console.error("Invalid Value: Current_c",json);
            break;
          case (typeof json.Imp_Energy=="undefined"):
            console.error("Invalid Value: Imp_Energy",json);
            break;
          case (typeof json.Exp_Energy=="undefined"):
            console.error("Invalid Value: Exp_Energy",json);
            break;
          case (typeof json.Freq=="undefined"):
            console.error("Invalid Value: Freq",json);
            break;
          case (typeof json.PF_t=="undefined"):
            console.error("Invalid Value: PF_t",json);
            break;
          case (typeof json.PF_a=="undefined"):
            console.error("Invalid Value: PF_a",json);
            break;
          case (typeof json.PF_b=="undefined"):
            console.error("Invalid Value: PF_b",json);
            break;
          case (typeof json.PF_c=="undefined"):
            console.error("Invalid Value: PF_c",json);
            break;
          case (typeof json.Power_t=="undefined"):
            console.error("Invalid Value: Power_t",json);
            break;
          case (typeof json.Power_a=="undefined"):
            console.error("Invalid Value: Power_a",json);
            break;
          case (typeof json.Power_b=="undefined"):
            console.error("Invalid Value: Power_b",json);
            break;
          case (typeof json.Power_c=="undefined"):
            console.error("Invalid Value: Power_c",json);
            break;
          case (typeof json.Volt_a=="undefined"):
            console.error("Invalid Value: Volt_a",json);
            break;
          case (typeof json.Volt_b=="undefined"):
            console.error("Invalid Value: Volt_b",json);
            break;
          case (typeof json.Volt_c=="undefined"):
            console.error("Invalid Value: Volt_c",json);
            break;
          default:
            // console.log(json)
            this.add_device_log(json);
        }
      } catch (e) {
        console.error(message);
        console.error(e);
      }
    },
    add_device_log(json){
      const INDEX = this.devices.map((d) => parseInt(d.number)).indexOf(parseInt(json.ID));
      if(INDEX!==-1){
        const ThisDevice = this.devices[INDEX];
        if(!parseInt(ThisDevice.enable)){
          console.error("This device is disable!",ThisDevice.number);
          return false;
        }
        const d_id = ThisDevice.id;

        Object.keys(json).forEach((key) => {
          json[key] = json[key].toString();
        });
        json.d_id = d_id;
        json.Number = json.ID;
        json.date_now = Date.now();
        var d = new Date(parseInt(json.date_now)),
        dformat = [d.getFullYear(),
                   d.getMonth()+1,
                   d.getDate()].join('-')+' '+
                  [this.leftPad(d.getHours(),2),
                   this.leftPad(d.getMinutes(),2),
                   this.leftPad(d.getSeconds(),2)].join(':');
        json.created = dformat;

        // console.log("add_device_log",json);
        this.device_log.unshift(json);
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    DeviceLogSort(){
      let DeviceLogSort = this.device_log;
      if(DeviceLogSort>1){
        DeviceLogSort = DeviceLogSort.sort((a, b) => {
          // if(parseInt(a.id)>parseInt(b.id)) return -1
          if(parseInt(a.date_now)>parseInt(b.date_now)) return -1
        })
      }
      return DeviceLogSort;
    }
  }
}
</script>

<style scoped="">
  #base{ background: #e9e9e9 !important; }
  .mainBase{
    min-height:900px;
    background:#e1e1e1;
  }
</style>
